import { GridColType } from '@mui/x-data-grid';

import { DateRangeType, DateValueType } from 'src/shared/ui/datepicker/types';

import { TicketStatus } from './tickets';

type DefaultEntity = {
  id: string;
  createDate: string;
  modifiedDate: string;
};

type PersonEntity = {
  id: string;
  firstName: string;
  lastName: string;
  providerPersonnelEmail?: string;
  providerPersonnelID: string;
  providerPersonnelPhone: string;
  createdBy?: string;
  modifiedBy?: string;
  createDate: string;
  modifiedDate: string;
  providerRoleMatrix: null;
  jobTitle: string;
  typicalWorkClassification?: string;
} & DefaultEntity;

type LaborEntity = {
  PeopleWorkRecordID: string;
  ProviderPersonnel?: PersonEntity;
  ContractRateSheet: ContractRateSheet;
  AcceptWork: string;
  Provider: Provider;
};

type TicketEntity = {
  id: number;
  projectName: string;
  job: string;
  owner: Owner;
  ownerLocation?: OwnerLocation;
  jobCategory: Category;
  po?: string;
  wo?: string;
  ownerContract: ContractList[][0];
  ownerContact?: Owner['OwnerPersonnel'][0];
  ownerRepresentative?: Owner['OwnerPersonnel'][0];
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  duration: string;
  shift: string;
  lunchAllowed: string;
  scheduledLunch: string;
  workNotes: string;
  createdBy: string;
  modifiedBy: string;
  createDate: string;
  modifiedDate: string;
  crewLeader: LaborEntity | null;
  supervisors: PersonEntity[];
  employees: PersonEntity[];
  labor: LaborEntity[];
  equipment: EquipmentEntity[];
  status: TicketStatus;
  thisShiftTimeOnYard?: string;
  thisShiftBaseTime?: string;
  JELL?: JELL;
  ticketNumber: string;
} & DefaultEntity;

type CopyTicket = {
  id: string;
  projectName: string;
  job: string;
  owner: Owner;
  ownerLocation: OwnerLocation;
  jobCategory: Category;
  po?: string;
  wo?: string;
  ownerContract: ContractList[][0];
  ownerContact?: Owner['OwnerPersonnel'][0];
  ownerRepresentative?: Owner['OwnerPersonnel'][0];
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  thisShiftTimeOnYard?: string;
  shift: string;
  lunchAllowed: string;
  scheduledLunch: string;
  workNotes: string;
  createdBy: string;
  modifiedBy: string;
  createDate: string;
  modifiedDate: string;
  crewLeader: LaborEntity | null;
  supervisors: PersonEntity[];
  employees: string[];
  equipment: string[];
  optimisticPeople: PersonEntity[];
  optimisticEquipment: EquipmentEntity[];
  JELL: JELL;
  duration: string;
};

type Category = {
  Id: string;
  Category: string;
};

type JobEntity = {
  id: string;
  projectName: string;
  owner: Owner;
  ownerLocation: OwnerLocation;
  ownerContract: ContractList[][0];
  ownerContact?: Owner['OwnerPersonnel'][0];
  ownerRepresentative?: Owner['OwnerPersonnel'][0];
  department: ProviderDivision;
  jobCategory: Category;
  po?: string;
  wo?: string;
  serviceLine: ServiceLine;
  serviceType?: ServiceLine['ServiceTypes'][0];
  requestedStartDate: DateRangeType['startDate'];
  requestedCompleteDate: DateRangeType['endDate'];
  startTime: string;
  endTime: string;
  isLunchAllowed: 'Yes' | 'No';
  createdBy: string;
  createDate: string;
  modifiedBy: string;
  modifiedDate: string;
  workRequestNotes: string;
  tickets: TicketEntity[];
  shiftType?: string;
  row?: number;
  sro?: string;
  JELL: JELL;
  isJELLEnforced: boolean;
  defaultScheduledStartTime?: string;
  defaultScheduledDuration?: string;
  defaultScheduledTimeOnYard?: string;
  defaultScheduledBaseTime?: string;
  jobHealth?: number;
} & DefaultEntity;

type EquipmentEntity = {
  UnitDescription: string;
  UnitNo: string;
  Type: string;
  AssetID: string;
  RequiresUnitNumber: string;
  UnitNumber: string;
  KITItemsRecordID: string;
  ContractRateSheet: ContractRateSheet;
  UOM: string;
  QTY: string;
  RentalUnitNumber: string;
  IsRental: boolean;
  type: EquipmentTabType;
} & DefaultEntity;

type MostUsedEquipment = {
  Type: string;
  TypeCount: number;
};

type MostUsedJobTitle = {
  JobTitle: string;
  JobTitleCount: number;
};

type AccessEntity = {
  ProviderPersonnelEmail: string;
  RoleID: string;
  ProviderID: string;
  ProviderPersonnel: {
    FirstName: string;
    LastName: string;
  };
  SystemAdmin: 'Yes' | 'No';
};

type Owner = {
  OwnerID: string;
  OwnerName: string;
  OwnerPersonnel: {
    OwnerPersonnelEmail: string;
    FirstName: string;
    LastName: string;
  }[];
};

type ContractList = {
  RateSheetID: string;
  RateSheetDescription: string;
  MasterorCustomerContract: string;
  OwnerID?: string;
  OwnerLocationID?: string;
};

type OwnerLocation = {
  OwnerID: string;
  OwnerLocationID: string;
  OwnerLocation: string;
  LocationTimezone: string;
  ContractList: ContractList[];
};

type ServiceLine = {
  ServiceLineID: string;
  ServiceLineDescription: string;
  ServiceTypes: {
    ServiceTypeID: string;
    ServiceTypeDescription: string;
  }[];
};

type ProviderDivision = {
  ProviderDivisionID: string;
  ProviderDivisionName: string;
  ProviderBranchID: string;
};

type ProviderBranch = {
  BranchID: string;
  BranchName: string;
};

type JELL = {
  AdditionalKitDescription1: string;
  EquipmentKitID: string;
  Contract: string;
};

type ContractRateSheet = {
  ItemCodeCategoryName: string;
  ItemCodeCategoryID: string;
  CustomItemDescription: string;
  Itemcode: string;
  RequiresUnitNumber: string;
  UOM: string;
  QTY: string;
  RentalItemCode: string;
  RateSheetItemID: string;
  BillType: string;
};

type EquipmentJELL = {
  KITItemsRecordID: string;
  EquipmentKITListID: string;
  ExtraQuantity: string;
  ContractRateSheet?: ContractRateSheet;
  RequiresUnitNumber: 'Yes' | 'No';
  UOM: string;
  QTY: string;
  UnitNumber: string;
  RentalUnitNumber: string;
  IsRental: string;
};

type LaborJELLItem = {
  LaborBuilderID: number;
  ProviderID: string;
  OwnerID: number;
  OwnerLocationID: number;
  RateSheetID: number;
  EquipmentKITListID: number;
  RateSheetItemID: number;
  DisplayOrder: number;
  Quantity: number;
  ScheduledWorkID: number;
  ContractRateSheet: ContractRateSheet;
};

type LaborJELLDetail = {
  ContractRateSheet?: ContractRateSheet;
  JobRole: string;
  PeopleWorkRecordID: string;
  ProviderPersonnelID: string;
  QTY: string;
  RateSheetID: string;
  RateSheetItemID: string;
  UOM: string;
  WorkClassificationstring: string;
  ProviderPersonnel?: {
    FirstName: string;
    LastName: string;
  };
};

type JELLItems = {
  requiredEquipment: EquipmentJELL[];
  requiredLabor: LaborJELLItem[];
};

type JELLDetails = {
  requiredEquipment: EquipmentJELL[];
  requiredLabor: LaborJELLDetail[];
};

type Provider = {
  LaborAcknowledgementDue: number;
  FileRootPath: string;
};

type ProviderPortal = {
  Id: string;
  PageTitle: string;
  Favicon: string;
  BannerLogo: string;
  PortalUrl?: string;
  ProviderPortalSections: ProviderPortalSection[];
};

type ProviderPortalSection = {
  Id: string;
  DisplayOrder: number;
  Title: string;
  ProviderPortalSectionItems: ProviderPortalSectionItem[];
};

type ProviderPortalSectionItem = {
  Id: string;
  Title: string;
  Icon: string;
  Link: string;
  HoverText: string;
  DisplayOrder: number;
};

type PageSize = 5 | 10 | 15 | 30 | 50;

type PaginationType = {
  currentPage: number;
  pageSize: PageSize;
  total: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: PageSize) => void;
};

type SurveyNotificationSubscription = {
  id: string;
  notificationId: string;
  userId: string;
};

type SurveyNotification = {
  id: string;
  name: string;
  subscriptions: SurveyNotificationSubscription[];
  emailTemplateId: string;
};

type SurveyNotificationTemplate = {
  id: string;
  name: string;
};

type SurveyNotificationDetail = {
  surveyNotification: SurveyNotification;
};

type SurveyEntity = {
  id: string;
  name: string;
  author: {
    id: string;
  };
  content: any;
  answers: Omit<SurveyAnswerEntity, 'responseId'>[];
  notifications: SurveyNotificationDetail[];
  createdAt: string;
  updatedAt: string;
};

type SurveyContent = Pick<SurveyEntity, 'id' | 'content' | 'createdAt' | 'updatedAt' | 'content'>;

type SurveyAnswerEntity = {
  id: string;
  answer: any;
  author: {
    id: string;
  };
  surveyId: string;
  createdAt: string;
  updatedAt: string;
  responseId: string | null;
};

type Error = {
  status: number;
};

const EquipmentTab = {
  owned: 'owned',
  rental: 'rental',
} as const;

type EquipmentTabType = keyof typeof EquipmentTab;

enum Role {
  SuperAdmin = 'SuperAdmin',
  Admin = 'Admin',
  SurveyReadonly = 'SurveyReadonly',
  SurveyUser = 'SurveyUser',
  User = 'User',
  Inactive = 'Inactive',
  SiteSuperUser = 'SiteSuperUser',
  TADashboardUser = 'TADashboardUser',
  TATrackerUser = 'TATrackerUser',
}

enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

type SortBy = {
  fieldKey: string;
  order: SortOrder;
};

type CustomGridColType = 'enum' | 'array' | GridColType;

type TypeNotation = {
  field: string;
  type: CustomGridColType;
  values?: string[];
  arrayItem?: TypeNotation;
  isNullable: boolean;
  isOptional: boolean;
  isEditable: boolean;
  defaultValue?: boolean;
};

type FilterField = {
  field: string;
  param?: string;
};

type ForeignKey = {
  field: TagType;
  model: string;
  filterFields?: FilterField[];
};

enum DefaultTableValue {
  email = 'email',
  ownerLocationId = 'ownerLocationId',
}

enum RequiredState {
  Required = 'required',
  Optional = 'optional',
}

type FieldMetadata = {
  hidden?: boolean;
  defaultValue?: DefaultTableValue;
  readonly?: boolean;
  requiredState?: RequiredState;
};

type FieldToolbar = {
  showSearch?: boolean;
  showMapBtn?: boolean;
  isCreateButtonHidden?: boolean;
};

type FieldMetadataMap = Record<string, FieldMetadata>;

type HeaderNames = {
  [key: string]: {
    name: string;
  };
};

type FieldConfiruration = {
  dateFormat?: string;
};
type FieldsConfirurations = {
  [key: string]: FieldConfiruration;
};

type GridColumns = {
  sm?: number;
  md?: number;
  lg?: number;
};

type CustomComponents = {
  [key: string]: {
    [key: string]: {
      componentName: string;
      field: string;
      gridColumns?: GridColumns;
      settings?: {
        modelName?: TagType;
        documentTypeFieldName?: string;
      };
    };
  };
};

type RelatedOnChangeFields = {
  [key: string]: {
    field: string;
    model: string;
    defaultValue: {
      [key: string]: TableManagerFieldValue;
    };
  };
};

type MetadataMap = {
  grid?: FieldMetadataMap;
  details?: FieldMetadataMap;
  create?: FieldMetadataMap;
  update?: FieldMetadataMap;
  toolbar?: FieldToolbar;
  multiSelectFields?: string[];
  headerNames?: HeaderNames;
  invalidatesTags?: TagType[];
  fieldsConfirurations?: FieldsConfirurations;
  customComponents?: CustomComponents;
  relatedOnChangeFields?: RelatedOnChangeFields;
};

type RowMetadata = {
  isEditingDisabled?: boolean;
  isDeletionDisabled?: boolean;
};

type RowsMetadata = Record<string, RowMetadata | null>;

type TableManagerResponse = {
  data: Record<string, TableManagerFieldValue>[];
  schema: TypeNotation[];
  idField: string;
  total: number;
  totalPages: number;
  foreignKeys: ForeignKey[];
  fieldsMetadata: MetadataMap;
  rowsMetadata?: RowsMetadata;
};
type SurveyAnswerResponse = {
  data: SurveyAnswerEntity[];
  total: number;
  totalPages: number;
};

type SurveyAnswersForExportResponse = Pick<SurveyAnswerResponse, 'data'>;

type RelationOption = {
  label: string;
  value: string;
};

type RelationOptionsMap = {
  [key: string]: RelationOption[];
};

type RelationValuesMap = {
  [key: string]: TableManagerFieldValue;
};

type User = {
  email: string;
  id: string;
};

type ModelInfo = {
  name: string;
  apiEndpoint: string;
  hidden?: boolean;
  label?: string;
};

type ModelsOptions = {
  [key: string]: ModelInfo;
};

type AdminDataResponse = {
  modelsOptions: ModelsOptions;
};

type AdminPageContextType = {
  modelsOptions: ModelsOptions;
  isFetchingModelsOptions: boolean;
};

type PaginationModelAdminPage = {
  pageSize: number;
  page: number;
};
type PaginationModelAdminPageDialog = {
  dialogPageSize: number;
  dialogPage: number;
};

type Inspector = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  city: string;
  state: string;
  street: string;
  zip: string;
  country: string;
  yearsOfExperience: number;
  latitude: number;
  longitude: number;
  inspectorToInspectorCertification: { inspectorCertification: InspectorCertification }[];
  inspectorToInspectorCompetency: {
    inspectorCompetency: InspectorCompetency;
  }[];
};

type InspectorWithPosition = Inspector & {
  position: PointTuple;
};

type InspectorCertification = {
  id: string;
  name: string;
};
type InspectorCompetency = {
  id: string;
  name: string;
};

type PointTuple = [number, number];
type BoundsTuple = [PointTuple, PointTuple];
type LatLng = {
  lat: number;
  lng: number;
};
type SearchResult<TRawResult = any> = {
  x: number;
  y: number;
  label: string;
  bounds: BoundsTuple | null;
  raw: TRawResult;
};

type RawResult = {
  place_id: string;
  licence: string;
  osm_type: string;
  osm_id: number;
  boundingbox: [string, string, string, string];
  lat: string;
  lon: string;
  display_name: string;
  class: string;
  type: string;
  importance: number;
  icon?: string;
};

type SurveyAnswerReportEntity = {
  'id': string;
  'category'?: string;
  'createdAt': string;
  'updatedAt': string;
  'CompletedBy'?: string;
  'CreateDate'?: string;
  'JobNum'?: string;
  'Shift'?: string;
  'Job Description'?: string;
  'ClientPlant'?: string;
  'Unit'?: string;
  'LocEquip'?: string;
  'Supervisor'?: string;
  'SupervSign'?: string;
};

type Document = {
  id: string;
  fileName: string;
  fileUrl: string;
  documentableId: string;
  documentableType: string;
  uploadedAt: string;
};

type CustomComponentName =
  | 'AdminSurveyFormNavigator'
  | 'DataGridContainerField'
  | 'FileUploadDialog'
  | 'DocumentTable';

type TagType =
  | 'Jobs'
  | 'Tickets'
  | 'People'
  | 'Equipment'
  | 'Configuration'
  | 'Auth'
  | 'Access'
  | 'OwnerLocations'
  | 'Owners'
  | 'ServiceLines'
  | 'ProviderDivisions'
  | 'ProviderBranches'
  | 'ContractList'
  | 'JobCategory'
  | 'Survey'
  | 'SurveyAnswer'
  | 'Provider'
  | 'Notification'
  | 'SurveyNotification'
  | 'ProviderRoleMatrix'
  | 'ProviderPersonnel'
  | 'Inspectors'
  | 'Visits'
  | 'Clients'
  | 'Assignments'
  | 'Projects';

type TableManagerFieldValue =
  | string
  | number
  | boolean
  | string[]
  | DateValueType
  | RelationOption
  | RelationOption[]
  | null
  | Pick<SurveyEntity, 'id' | 'name'>[];

export type {
  JobEntity,
  TicketEntity,
  PersonEntity,
  MostUsedJobTitle,
  EquipmentEntity,
  MostUsedEquipment,
  ContractRateSheet,
  DefaultEntity,
  AccessEntity,
  Owner,
  OwnerLocation,
  ServiceLine,
  ProviderDivision,
  ProviderBranch,
  Category,
  CopyTicket,
  JELL,
  JELLItems,
  EquipmentJELL,
  LaborJELLItem,
  LaborJELLDetail,
  JELLDetails,
  ProviderPortal,
  ContractList,
  PaginationType,
  PageSize,
  LaborEntity,
  SurveyEntity,
  SurveyAnswerEntity,
  Provider,
  Error,
  EquipmentTabType,
  SurveyNotificationTemplate,
  SurveyNotification,
  TableManagerResponse,
  TagType,
  TypeNotation,
  TableManagerFieldValue,
  RelationOption,
  ForeignKey,
  RelationOptionsMap,
  User,
  MetadataMap,
  RowsMetadata,
  AdminDataResponse,
  ModelsOptions,
  AdminPageContextType,
  SurveyAnswerResponse,
  SurveyAnswersForExportResponse,
  SurveyContent,
  PaginationModelAdminPage,
  Inspector,
  InspectorWithPosition,
  PointTuple,
  LatLng,
  SearchResult,
  RawResult,
  SortBy,
  SurveyAnswerReportEntity,
  CustomComponentName,
  RelationValuesMap,
  CustomComponents,
  GridColumns,
  PaginationModelAdminPageDialog,
  Document,
};

export { Role, SortOrder, EquipmentTab, DefaultTableValue, RequiredState };
export type { ProviderPortalSection, ProviderPortalSectionItem };
