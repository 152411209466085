import { Dispatch, FC, memo, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Model, SurveyModel } from 'survey-core';
import {
  Tabulator,
  TableExtensions,
  GetDataUsingPromiseFn,
} from 'survey-analytics/survey.analytics.tabulator';
import { DocumentHelper } from 'survey-analytics';

import { selectCurrentUser } from 'src/store/slices';
import { not } from 'src/shared/utils';
import { Role, SurveyAnswerEntity, SurveyContent } from 'src/shared/types';
import { ADMIN_ROLES } from 'src/shared/constants';

import 'tabulator-tables/dist/css/tabulator.min.css';
import 'survey-analytics/survey.analytics.tabulator.css';
import { ExtraSurveyAnswerField } from '../../constants';

export const SURVEY_ANSWERS_DOWNLOADER_CONTAINER_ID = 'survey-answers-downloader-container';
const CONTAINER_ID = 'survey-answers-container';

interface IVisualizationPanelProps {
  form: SurveyContent | undefined;
  answers: SurveyAnswerEntity[];
  deleteAnswer: any;
  setIsDeleteSurveyAnswerModalOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedSurveyAnswer: Dispatch<SetStateAction<SurveyAnswerEntity | undefined>>;
  onSurveyInitialized: (survey: SurveyModel) => void;
  getTabulatorSurveyData: GetDataUsingPromiseFn;
}

const VisualizationPanel: FC<IVisualizationPanelProps> = ({
  form,
  answers,
  deleteAnswer,
  setIsDeleteSurveyAnswerModalOpen,
  setSelectedSurveyAnswer,
  onSurveyInitialized,
  getTabulatorSurveyData,
}) => {
  const navigate = useNavigate();
  const [vizPanel, setVizPanel] = useState<Tabulator>();
  const { formId } = useParams();

  const user = useSelector(selectCurrentUser);

  const userRole = user?.ProviderRoleMatrix?.userRole as Role | undefined;
  const isReadonly =
    user?.ProviderRoleMatrix?.userRole &&
    [Role.SurveyReadonly, Role.SiteSuperUser].includes(user.ProviderRoleMatrix.userRole as Role);

  useEffect(() => {
    if (not(form)) {
      return;
    }

    const survey = new Model(form.content);

    Object.values(ExtraSurveyAnswerField).forEach((fieldName) => {
      survey.pages[0].addNewQuestion('text', fieldName);
    });

    onSurveyInitialized(survey);

    const visualizationPanel = new Tabulator(survey as any, getTabulatorSurveyData, {
      tabulatorOptions: {
        pagination: false,
      },
      downloadButtons: [],
    });

    setVizPanel(visualizationPanel);
  }, [form, navigate, answers]);

  useEffect(() => {
    if (vizPanel) {
      vizPanel.render(CONTAINER_ID);

      const tableHeader = document.querySelector(`#${CONTAINER_ID} .sa-tabulator__header`);
      tableHeader?.classList?.add('hidden');

      const viewAnswerName = 'view-answer';
      const editAnswerName = 'edit-answer';
      const deleteAnswerName = 'delete-answer';

      TableExtensions.unregisterExtension('details', viewAnswerName);
      TableExtensions.unregisterExtension('details', editAnswerName);
      TableExtensions.unregisterExtension('details', deleteAnswerName);

      TableExtensions.registerExtension({
        location: 'details',
        name: viewAnswerName,
        visibleIndex: 1,
        render: (table, opt) => {
          const btn = DocumentHelper.createElement(
            'button',
            'sa-table__btn sa-table__btn--small mr-1 transition-all hover:opacity-[0.8] duration-200',
            {
              innerHTML: 'View',
              onclick: () => {
                if (not(form)) {
                  return;
                }

                const answer = answers[opt.row.getDataPosition()];
                navigate(`/forms/${formId}/answers/${answer.id}`);
              },
            },
          );

          return btn;
        },
      });

      if (not(isReadonly)) {
        TableExtensions.registerExtension({
          location: 'details',
          name: editAnswerName,
          visibleIndex: 1,
          render: (table, opt) => {
            const btn = DocumentHelper.createElement(
              'button',
              'sa-table__btn sa-table__btn--small mr-1 transition-all hover:opacity-[0.8] duration-200',
              {
                innerHTML: 'Edit',
                onclick: () => {
                  if (not(form)) {
                    return;
                  }

                  const answer = answers[opt.row.getDataPosition()];
                  navigate(`/forms/${formId}/answers/${answer.id}/edit`);
                },
              },
            );

            return btn;
          },
        });

        if (!userRole || !ADMIN_ROLES.includes(userRole)) {
          return;
        }

        TableExtensions.registerExtension({
          location: 'details',
          name: deleteAnswerName,
          visibleIndex: 1,
          render: (table, opt) => {
            const btn = DocumentHelper.createElement(
              'button',
              'sa-table__btn sa-table__btn--small border border-semanticColor-danger text-white !bg-semanticColor-danger transition-all hover:opacity-[0.8] duration-200',
              {
                innerHTML: 'Delete',
                onclick: async () => {
                  if (not(form)) {
                    return;
                  }

                  const answer = answers[opt.row.getDataPosition()];

                  setIsDeleteSurveyAnswerModalOpen(true);
                  setSelectedSurveyAnswer(answer);
                },
              },
            );
            return btn;
          },
        });
      }
    }
  }, [deleteAnswer, form, formId, isReadonly, navigate, userRole, vizPanel, answers]);

  return (
    <div className="w-full h-[70%]">
      <div
        id={SURVEY_ANSWERS_DOWNLOADER_CONTAINER_ID}
        className="hidden"
      />

      <div id={CONTAINER_ID} />
    </div>
  );
};

const MemoizedVisualizationPanel = memo(VisualizationPanel);

export { MemoizedVisualizationPanel as VisualizationPanel };
