import { FC, useEffect, useState, useRef } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

import {
  TagType,
  AdminPageContextType,
  PaginationModelAdminPage,
  TableManagerFieldValue,
  PaginationModelAdminPageDialog,
} from 'src/shared/types';
import { useDebounce } from 'src/shared/hooks/useDebounce';
import { useGetTableDataQuery, useGetRelationOptionsQuery } from 'src/store/api';
import { selectTableState, TypeAdminPageModal } from 'src/store/slices';

import { CustomDataGrid } from '../CustomDataGrid';
import { ToolbarDataGrid } from '../ToolbarDataGrid';
import { CreateOrUpdateTableRowModalField } from '../CreateOrUpdateTableRowModalField';
import { DetailsTableRowModalField } from '../DetailsTableRowModalField';

type DataGridContainerFieldProps = {
  modelName: TagType;
  state: Record<string, TableManagerFieldValue>;
  parentIdField: string;
};

const SEARCH_FIELD_DELAY = 500;

const WrapperDataGridContainerField = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '.5rem',
  border: '1px solid #C4C4C4',
  borderRadius: '4px',
  height: '100%',
}));

const DataGridContainerField: FC<DataGridContainerFieldProps> = ({
  modelName,
  state,
  parentIdField,
}) => {
  const { modelsOptions, isFetchingModelsOptions } = useOutletContext<AdminPageContextType>();
  const [isCreateOrUpdateTableRowModalOpen, setIsCreateOrUpdateTableRowModalOpen] = useState(false);
  const [isDetailsTableRowModalOpen, setIsDetailsTableRowModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Record<string, TableManagerFieldValue> | null>(
    null,
  );

  const { selectedRowId, typeModal } = useSelector(selectTableState);

  const [searchParams, setSearchParams] = useSearchParams();

  const [searchField, setSearchField] = useState<string>(searchParams.get('dialogSearch') || '');

  const debouncedSearchField = useDebounce(searchField, SEARCH_FIELD_DELAY);

  const timerId = useRef<NodeJS.Timeout | null>(null);

  const [paginationModel, setPaginationModel] = useState<PaginationModelAdminPageDialog>({
    dialogPageSize: parseInt(searchParams.get('dialogPageSize') || '10', 10),
    dialogPage: parseInt(searchParams.get('dialogPage') || '0', 10),
  });
  const {
    data = {
      data: [],
      schema: [],
      idField: '',
      total: 0,
      totalPages: 0,
      foreignKeys: [],
      fieldsMetadata: {},
    },
    isLoading,
    isFetching,
  } = useGetTableDataQuery(
    {
      apiEndpoint: modelsOptions?.[modelName].apiEndpoint,
      modelName,
      pageSize: String(paginationModel.dialogPageSize),
      page: String(paginationModel.dialogPage + 1),
      filters: {
        search: debouncedSearchField.trim(),
        ...(state?.[parentIdField] && {
          parentId: state[parentIdField] as string,
        }),
      },
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: tableData,
    schema,
    idField,
    total,
    foreignKeys = [],
    fieldsMetadata = {},
    rowsMetadata,
  } = data;

  const {
    data: relationOptions = {},
    isLoading: isLoadingRelationOptions,
    isFetching: isFetchingRelationOptions,
  } = useGetRelationOptionsQuery(
    { models: foreignKeys, invalidatesTags: fieldsMetadata?.invalidatesTags },
    {
      skip: foreignKeys.length === 0,
    },
  );

  const handleSearchFieldChange = (searchFieldValue: string) => {
    setSearchField(searchFieldValue);

    if (searchField && paginationModel.dialogPage !== 0) {
      setPaginationModel((prev) => ({
        ...prev,
        dialogPage: 0,
      }));
    }
  };

  const handleDetailsModal = (id: string | number | null) => {
    const selectedRow = tableData.find((el) => el[idField] === id);

    if (selectedRow) {
      setSelectedRow(selectedRow);

      setIsDetailsTableRowModalOpen(true);
    }
  };

  const handleUpdateModal = (id: string | number | null) => {
    const selectedRow = tableData.find((el) => el[idField] === id);

    if (selectedRow) {
      setSelectedRow(selectedRow);

      setIsCreateOrUpdateTableRowModalOpen(true);
    }
  };

  useEffect(() => {
    if (selectedRowId && typeModal === TypeAdminPageModal.Details) {
      handleDetailsModal(selectedRowId);
    }
    if (selectedRowId && typeModal === TypeAdminPageModal.Create) {
      setIsCreateOrUpdateTableRowModalOpen(true);
    }

    if (selectedRowId && typeModal === TypeAdminPageModal.Update) {
      handleUpdateModal(selectedRowId);
    }
  }, [selectedRowId, isLoading, isFetching]);

  useEffect(() => {
    return () => {
      const newSearchParams = new URLSearchParams(searchParams.toString());

      newSearchParams.delete('dialogSearch');
      newSearchParams.delete('dialogPageSize');
      newSearchParams.delete('dialogPage');

      setSearchParams(newSearchParams);
    };
  }, []);

  useEffect(() => {
    if (timerId?.current) {
      clearTimeout(timerId.current);
    }

    timerId.current = setTimeout(() => {
      const newSearchParams = new URLSearchParams(searchParams.toString());
      if (!debouncedSearchField) {
        newSearchParams.delete('dialogSearch');

        setSearchParams(newSearchParams);
      } else {
        newSearchParams.set('dialogSearch', searchField);
      }
      setSearchParams(newSearchParams);
    }, SEARCH_FIELD_DELAY);

    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchField]);

  return (
    <WrapperDataGridContainerField>
      {isLoading ||
        isLoadingRelationOptions ||
        (isFetchingModelsOptions && (
          <div className="w-full h-full flex justify-center items-center">
            <CircularProgress />
          </div>
        ))}
      <ToolbarDataGrid
        modelName={modelName}
        setIsOpen={setIsCreateOrUpdateTableRowModalOpen}
        searchField={searchField}
        isFetching={isFetching}
        handleChange={handleSearchFieldChange}
        fieldsMetadata={fieldsMetadata}
      />
      <CustomDataGrid
        modelName={modelName}
        schema={schema}
        tableData={tableData}
        isLoading={isLoading}
        paginationModel={paginationModel}
        setPaginationDialogModel={setPaginationModel}
        idField={idField}
        total={total}
        relationOptions={relationOptions}
        isFetching={isFetching}
        isLoadingRelationOptions={isLoadingRelationOptions}
        isFetchingRelationOptions={isFetchingRelationOptions}
        setSelectedRow={setSelectedRow}
        setIsCreateOrUpdateTableRowModalOpen={setIsCreateOrUpdateTableRowModalOpen}
        fieldsMetadata={fieldsMetadata}
        rowsMetadata={rowsMetadata}
        position="relative"
        intoDialog
      />

      {isCreateOrUpdateTableRowModalOpen && (
        <CreateOrUpdateTableRowModalField
          schema={schema}
          idField={idField}
          isOpen={isCreateOrUpdateTableRowModalOpen}
          setIsOpen={setIsCreateOrUpdateTableRowModalOpen}
          modelName={modelName}
          relationOptions={relationOptions}
          fieldsMetadata={fieldsMetadata}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
        />
      )}

      {isDetailsTableRowModalOpen && (
        <DetailsTableRowModalField
          schema={schema}
          isOpen={isDetailsTableRowModalOpen}
          setIsOpen={setIsDetailsTableRowModalOpen}
          modelName={modelName}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          relationOptions={relationOptions}
          fieldsMetadata={fieldsMetadata}
        />
      )}
    </WrapperDataGridContainerField>
  );
};

export { DataGridContainerField };
