import {
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';

import { ReactComponent as ChevronDownIcon } from 'src/assets/icons/filled/chevrons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'src/assets/icons/filled/chevrons/chevron-up.svg';
import { IconButton } from 'src/shared/ui/iconButton';
import { Size as IconSize } from 'src/shared/ui/iconButton/iconButton.types';
import { not } from 'src/shared/utils';
import { styled, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';

type AccordionProps = PropsWithChildren &
  Omit<HTMLAttributes<HTMLDivElement>, 'title'> & {
    defaultOpen?: boolean;
    iconPosition?: 'left' | 'right';
    iconSize?: IconSize;
    title: ReactNode;
    preTitle?: ReactNode;
    shouldRenderTitleAsFirstCard?: boolean;
    open?: boolean;
    hideIcon?: boolean;
    titleRowClassname?: string;
    handleAccordionChange?: (isOpen: boolean) => void;
  };

const Accordion = forwardRef<HTMLDivElement, AccordionProps>(
  (
    {
      iconPosition = 'right',
      iconSize = 'md',
      children,
      title,
      preTitle,
      shouldRenderTitleAsFirstCard,
      hideIcon = false,
      titleRowClassname,
      className,
      handleAccordionChange,
      open,
      defaultOpen: isDefaultOpen = true,
      ...props
    },
    ref,
  ) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [internalIsOpen, setIsOpen] = useState(isDefaultOpen);
    const titleRef = useRef<HTMLDivElement>(null);
    const isOpen = typeof open !== 'undefined' ? open : internalIsOpen;

    useEffect(() => {
      setIsOpen(isDefaultOpen);
    }, [isDefaultOpen]);

    const handleAccordionClick = () => {
      if (handleAccordionChange) {
        handleAccordionChange(isOpen);
      } else {
        setIsOpen(!isOpen);
      }
    };

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip
        {...props}
        classes={{ popper: className }}
      />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#F6F9FD',
        opacity: 1,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        minWidth: '344px',
        border: '1px solid #E0E0E0',
      },
    }));

    return (
      <LightTooltip
        title={<div className="flex justify-between items-center p-2">{preTitle}</div>}
        placement="top"
        disableFocusListener={shouldRenderTitleAsFirstCard}
        disableHoverListener={shouldRenderTitleAsFirstCard}
        sx={{
          '& .MuiTooltip-tooltip': {
            padding: 0,
            opacity: 1,
            borderRadius: '4px',
            backgroundColor: '#F6F9FD',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
          },
        }}
      >
        <div
          {...props}
          ref={ref}
          className={clsx('flex flex-col w-full', className, not(isOpen) && 'overflow-hidden')}
          style={{
            ...props?.style,
          }}
        >
          <div
            ref={titleRef}
            className="flex flex-col justify-between w-full cursor-pointer"
            onClick={handleAccordionClick}
          >
            {shouldRenderTitleAsFirstCard && (
              <div className="flex justify-between items-center mb-3 h-[18px]">{preTitle}</div>
            )}

            <div
              className={clsx('flex items-center justify-between gap-x-2 grow', titleRowClassname)}
            >
              {iconPosition === 'left' && !hideIcon && (
                <IconButton
                  onClick={handleAccordionClick}
                  className="bg-bgColor-white p-1"
                  size={iconSize}
                >
                  {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </IconButton>
              )}

              {title}

              {iconPosition === 'right' && !hideIcon && (
                <IconButton
                  onClick={handleAccordionClick}
                  className="bg-bgColor-white p-1"
                  size={iconSize}
                >
                  {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </IconButton>
              )}
            </div>
          </div>

          {!!children && isOpen && (
            <div
              className={clsx('transition-[opacity] duration-300 ease-in-out grow')}
              style={{
                opacity: Number(isOpen),
              }}
            >
              {children}
            </div>
          )}
        </div>
      </LightTooltip>
    );
  },
);

Accordion.displayName = 'Accordion';

export { Accordion };
export type { AccordionProps };
